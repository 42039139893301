import { render, staticRenderFns } from "./ToolsBanner.vue?vue&type=template&id=e2850ff0&scoped=true&lang=pug"
import script from "./ToolsBanner.vue?vue&type=script&lang=js"
export * from "./ToolsBanner.vue?vue&type=script&lang=js"
import style0 from "./ToolsBanner.vue?vue&type=style&index=0&id=e2850ff0&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e2850ff0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlyHeading: require('/app/components/elements/typography/Heading.vue').default,LoverlyIcon: require('/app/components/elements/typography/Icon.vue').default,LoverlyImage: require('/app/components/elements/Image.vue').default,LoverlyCarousel: require('/app/components/elements/containers/Carousel.vue').default})
