import { render, staticRenderFns } from "./index.vue?vue&type=template&id=c78dbd3e&scoped=true&lang=pug"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=c78dbd3e&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c78dbd3e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlyHomeHero: require('/app/components/pages/home/HomeHero.vue').default,LoverlyFeatureSection: require('/app/components/blocks/FeatureSection.vue').default,LoverlyPricingBanner: require('/app/components/blocks/PricingBanner.vue').default,LoverlyInspirationStyles: require('/app/components/blocks/InspirationStyles.vue').default,LoverlyListBlock: require('/app/components/blocks/ListBlock.vue').default,LoverlyHeading: require('/app/components/elements/typography/Heading.vue').default,LoverlyIcon: require('/app/components/elements/typography/Icon.vue').default,LoverlyButton: require('/app/components/inputs/Button.vue').default,LoverlyToolsBanner: require('/app/components/blocks/ToolsBanner.vue').default,LoverlyCategoryList: require('/app/components/pages/articles/CategoryList.vue').default,LoverlyCategoryItem: require('/app/components/pages/articles/CategoryItem.vue').default,LoverlyCarousel: require('/app/components/elements/containers/Carousel.vue').default,LoverlyCourseList: require('/app/components/pages/courses/CourseList.vue').default,LoverlyAppearances: require('/app/components/blocks/Appearances.vue').default,LoverlySeoBlock: require('/app/components/blocks/SeoBlock.vue').default})
